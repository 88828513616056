<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="mt-9">
    <!--    <new-logo class="h-30 w-30"/>-->
    <div class="max-w-7xl mx-auto py-16 sm:px-6 lg:px-0">
      <div class="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-4">
        <div class="mt-12 sm:mt-16 md:mt-0 mb-10 bg-white h-700 flex items-center p-5 rounded-md shadow-xl">
          <div class="w-full">
            <div class="flex items-center justify-between">
              <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                ICD Group
              </h2>
              <img src="/logo2.png" class="w-40 h-20 mb-4">
            </div>
            <div class="mt-8 space-y-9">

              <div class="flex">
                <div class="flex-shrink-0">
                  <font-awesome-icon icon="fa-solid fa-location-dot" class="h-6 w-6 mr-1 text-gray-500" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <p>
                    Majdanska Čukarica 16, {{ loc.belgrade_serbia }}
                  </p>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <font-awesome-icon icon="fa-solid fa-phone" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <a href="tel:+381 66 8027 033" class="hover:underline">
                    +381 66 8027 033
                  </a>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <font-awesome-icon icon="fa-solid fa-phone" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <a href="tel:+381 66 8027 033" class="hover:underline">
                    +381 64 2384 538
                  </a>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <font-awesome-icon icon="fa-solid fa-phone" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <a href="tel:+381 66 8027 033" class="hover:underline">
                    +381 64 2493 032
                  </a>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <font-awesome-icon icon="fa-solid fa-phone" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <a href="tel:+382 68 595 555" class="hover:underline">
                    +382 68 595 555
                  </a>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
<!--                  <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />-->
                   <font-awesome-icon icon="fa-solid fa-envelope" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <a href="mailto:support@hob.rs" class="hover:underline">
                    support@hob.rs
                  </a>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
<!--                  <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />-->
                  <font-awesome-icon icon="fa-solid fa-clock" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <p>{{ loc.working_hours }}</p>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <font-awesome-icon icon="fa-solid fa-globe" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <a href="https://hob.rs/" target="_blank" class="hover:underline">
                    www.hob.rs
                  </a>
                </div>
              </div>

              <div class="flex">
                <div class="flex-shrink-0">
                  <!--                  <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />-->
                  <font-awesome-icon icon="fa-solid fa-building" class="h-5 w-5 text-gray-500 ml-0.5" />
                </div>
                <div class="ml-3 text-base text-gray-500">
                  <p>{{ loc.vat_number }}: 110358663, &nbsp; {{ loc.registration_number }}: 21342408</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full bg-white bg-white h-700 p-1 rounded-md shadow-xl" style="aspect-ratio: 1/1;" v-html='`<div class="mapouter"><div class="gmap_canvas">
          <iframe src="https://maps.google.com/maps?q=Majdanska%20%C4%8Cukarica%2016,%20Beograd,%20Srbija&t=&z=13&ie=UTF8&iwloc=&output=embed" width="600" height="700" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <a href="https://fmovies-online.net"></a><br><style>.mapouter{text-align:right; width: 100%; height: 100%;}</style><a href="https://www.embedgooglemap.net"></a><style>.gmap_canvas {overflow:hidden;background:none!important; height: 100%; width: 100%;}</style></div></div>`'>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MailIcon, PhoneIcon, MapIcon, CursorClickIcon } from '@heroicons/vue/outline'
import NewLogo from "@/components/ui/LoginLogo";

export default {
  components: {
    NewLogo,
    MailIcon,
    PhoneIcon,
    MapIcon,
    CursorClickIcon
  },
}
</script>